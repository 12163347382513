import React from 'react';

export const Footer = () => {
    return (
        <div className='footer'>
            <div>
                {/*<a href="https://sourl.cn/Diz7FR"*/}
                {/*   className='bottomLinkText '*/}
                {/*   target='_blank'>*/}
                {/*    🔥 Get Source Code*/}
                {/*</a>*/}

                <div className='signature'>
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='mailto:hello@cvbox.org'
                        className='boldText '>
                        @contact us
                    </a>
                </div>
            </div>

        </div>


    );
};
